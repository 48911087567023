
@mixin Citizen {
	.active-citizen {
		max-width: 640px;
		margin-top: 64px;
		border: 2px solid rgba(255,255,255,0.8);
		padding: 16px;
		box-sizing: border-box;
		border-radius: 8px;
	}

	.bal--0 {
		display: none;
	}
	.no-citizen-controls {
		display: none;
	}
	.no-citizen-controls.bal--0 {
		display: block;
	}

	.active-citizen-controls {
		button {
			margin: 4px;
		}
	}

	.citizen--true {
		display: none;
	}

	.refresh {
		position: fixed;
		top: 16px;
		left: 16px;
	}
}