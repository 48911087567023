@use "src/_theme.scss";

@mixin Token {
	.simpleToken {
		height: 32px;
		width: 32px;
		img {
			object-fit: fill;
			height: 100%;
			width: 100%;
			filter: drop-shadow(2px 2px 2px black);
		}
	}

}