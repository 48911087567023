@use "src/_theme.scss";
@use "src/_utils.scss";
@mixin Wallet {
.wallet-container {
	
	overflow-x: scroll;

	background: rgba(theme.$bg,0.6);
  	border: 1px solid rgba(white, 0.4);
  	filter: drop-shadow(2px 2px 2px black);
	box-sizing: border-box;
	padding: 32px;
	position: relative;
	width: 100%;
	transform: translateX(0) translateY(0);
	transition: 0.2s transform ease-in-out, 0.2s background ease-in-out;
	@include utils.scrolly;
	button {
		display: none;
		position: absolute;
		top: -32px;
		left: 0;
		font-size: 16px;
		transition: 0.2s transform ease-in-out;
		transform: translateX(calc(75vw - 204px)) translateY(0);
	}


	table {

		$w1: 48px;
		$w2: 96px;
		$supColor: scale-color(theme.$pink, $lightness: 80%);
		$balColor: scale-color(theme.$green, $lightness: 80%);
		$supColorX: theme.$pink;
		$balColorX: theme.$green;
		$textColor: white;
		box-sizing: border-box;
	
		border-collapse: collapse;
		$s: 64px;
		tr.headhead th {
			&.sup, &.bal, &.tdtext {
				text-align: center;
				@include theme.altFont;
				font-size: 24px;
			}
			
		}
		tr {
			height: $s;
			font-size: 14px;
			th, td {
				padding: 0px 8px;
			}
			td {
				border-bottom: 1px dashed rgba(white,0.5);

			}

			th {
				&.sup {
					border-bottom: 1px solid $supColor;
					border-right: 1px dotted $supColor;
				}
				&.bal {
					border-bottom: 1px solid $balColor;
					border-right: 1px dotted $balColor;
				}
				&.tdtext {
					border-bottom: 1px solid $textColor;
					border-right: 1px dotted $textColor;
				}
			}

			.sup {
				color: $supColor;
				width: $w2;
				text-align: right;
				&.special {
					color: $supColorX;
				}
				
			}
			.bal {
				width: $w2;
				color: $balColor;
				text-align: right;
				&.special {
					color: $balColorX;
				}
			}
			.tdtext {
				color: $textColor;
				width: $w2;
				text-align: left;
				font-size: 12px;
				
				&.smallaf {
					width: $w2/2;
				}
				&.r {
					text-align: right;
				}
				
				
			}
			th.tdtoken, td.tdtoken  {

					position: absolute;
					width: $s;
					height: $s;
					left: 0;
					top: auto;
					position: -webkit-sticky;
					  position: sticky;
					  background: rgba(black, 0.8);
					  cursor: pointer;
					  &:hover {
					  	@include theme.dgrad(theme.$green,theme.$pink);
					  }
			
				
			}

			
			
		


		}
	}
	
}
}