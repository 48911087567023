@mixin City {
	.city {
		display: none;
		&-shio--shio,
		&-toray--toray,
		&-lauren--lauren,
		&-terrara--terrara,
		&-nibo--nibo,
		&-arbo--arbo {
			display: block;

		}
	}
}